import { isEqual } from "lodash";
import { memo, useCallback, useState } from "react";
import Dots from "../../../../components/Dots/Dots";
import CommunityLink from "../widget/CommunityLink/CommunityLink";
import Button from "../../../../components/Button/Button";
import "./Social.scss";
import HomeSection from "../widget/HomeSection/HomeSection";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const Subscribe = memo(() => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useCustomTranslation();
  // const onSubscribe = () => {
  //   setLoading(true);
  //   const auth = "Basic VmVuaWNlRmluYW5jZTpGaW5kb3JhMTIz";
  //   var url = `https://mail.zypher.game/abi?do=ml-sub&list_id=2603964&address=${value}`;
  //   var httpRequest = new XMLHttpRequest();
  //   httpRequest.open("GET", url);
  //   httpRequest.setRequestHeader("Authorization", auth);
  //   httpRequest.send();
  //   httpRequest.onreadystatechange = function () {
  //     if (httpRequest.readyState === 4 && httpRequest.status === 200) {
  //       const parser = new DOMParser();
  //       const doc = parser.parseFromString(
  //         httpRequest.responseText,
  //         "application/xml"
  //       );
  //       const status = doc.querySelector("status");
  //       if (status) {
  //         if (status.innerHTML === "addr_exist") {
  //           alert(
  //             "Subscription failed, the email address has already been subscribed"
  //           );
  //         }
  //         if (status.innerHTML === "param_error") {
  //           alert("User name is empty error");
  //         }
  //         if (status.innerHTML === "success") {
  //           alert("Subscription successful");
  //         }
  //       }
  //       setValue("");
  //       setLoading(false);
  //     }
  //   };
  // };
  const onSubscribe = useCallback(() => {
    // handleEmailSubscribe(value);
    window.open("https://zyphergames.substack.com/");
  }, [value]);
  return (
    <div className="input_box">
      {/* <div className="input_box_input">
        <input
          type="text"
          value={value}
          onChange={(e) => {
            const { value } = e.target;
            setValue(value);
          }}
          placeholder={t("Your Email Address")}
        />
      </div> */}
      <Button
        className="subscribe_btn"
        classNameInner="subscribe_btn_inner"
        label={t("Subscribe")}
        right={loading ? <Dots /> : null}
        onClick={onSubscribe}
      />
    </div>
  );
}, isEqual);
const Social = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="animate">
      <ul className="social_items">
        <li className="social_li1">
          <div className="social_inner">
            <div className="social_inner_fl">
              <h3>{t("Join the Community")}</h3>
              <p>
                {t(
                  "Connect, collaborate, and create in the Zypher gaming universe."
                )}
              </p>
            </div>
            <CommunityLink className={"social_community"} />
          </div>
        </li>
        <li className="social_li2">
          <div className="social_inner">
            <div className="social_inner_fl">
              <h3>{t("Subscribe to Our Newsletter")}</h3>
              <p>{t("Get the latest news and updates.")}</p>
            </div>
            <Subscribe />
          </div>
        </li>
      </ul>
    </HomeSection>
  );
}, isEqual);
export default Social;
