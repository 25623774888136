import { isEqual } from "lodash";
import { memo } from "react";
import "./Button.scss";
const Button = memo(
  ({
    className,
    right,
    label,
    onClick,
    classNameInner,
  }: {
    className?: string;
    classNameInner?: string;
    right?: any;
    label: string;
    onClick?: any;
  }) => {
    return (
      <div className={`btn ${className ?? ""}`} onClick={onClick}>
        <div className={`btnInner ${classNameInner ?? ""}`}>
          <p>{label}</p>
          {right ?? null}
        </div>
      </div>
    );
  },
  isEqual
);
export default Button;
