import { DialogContent, DialogOverlay } from "@reach/dialog";
import { isEqual } from "lodash";
import { memo, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showMenuState } from "../../state/home";
import "./MenuDialog.scss";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
const MenuDialog = memo(() => {
  const isModalOpen = useRecoilValue(showMenuState);
  const setIsModalOpen = useSetRecoilState(showMenuState);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  return (
    <DialogOverlay
      isOpen={isModalOpen}
      onDismiss={handleCancel}
      className="menu_dialog"
    >
      <DialogContent>
        <Header />
        <h3 className="menu_dialog_title">Build</h3>
        <h3 className="menu_dialog_title">Solutions</h3>
        <h3 className="menu_dialog_title">Games</h3>
        <Footer showLogo={false} />
      </DialogContent>
    </DialogOverlay>
  );
}, isEqual);
export default MenuDialog;
