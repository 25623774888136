import { memo, useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";
import { IID } from "../../Header/Header";
import {
  buildScrollRefState,
  daoScrollRefState,
  solutionsScrollRefState,
  xxScrollRefState,
} from "../../../state/home";
import "./HomeSection.scss";

const HomeSection = memo(
  ({
    classNames,
    children,
    id,
  }: {
    classNames?: string;
    children: React.ReactNode;
    id?: IID;
  }) => {
    const myRef = useRef<HTMLHeadingElement>(null);
    const setScrollRef = useSetRecoilState(
      id === "build"
        ? buildScrollRefState
        : id === "dao"
        ? daoScrollRefState
        : id === "solutions"
        ? solutionsScrollRefState
        : xxScrollRefState
    );

    useEffect(() => {
      if (id && myRef) {
        setScrollRef(myRef);
      }
    }, [id, setScrollRef, myRef]);

    return (
      <div className={`home_section ${classNames || ""}`} id={id} ref={myRef}>
        {children}
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.classNames === nextProps.classNames &&
    prevProps.id === nextProps.id &&
    prevProps.children === nextProps.children
);

export default HomeSection;
