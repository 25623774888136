import "./iconMenu.scss";
const IconMenu = ({ active }: { active: boolean }) => {
  return (
    <div className={`icon_menu ${active ? "active" : ""}`}>
      <span />
    </div>
  );
};

export default IconMenu;
