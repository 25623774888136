import { useCallback } from "react";

export const useClick = () => {
  const handleWhite = useCallback(() => {
    window.open("/whitepaper", "_blank");
  }, []);
  const handleEconomics = useCallback(() => {
    window.open("/economics", "_blank");
  }, []);
  return {
    handleWhite,
    handleEconomics,
  };
};
