import { isEqual } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Nav.scss";
import { preStaticUrl } from "../../../../constants/env_config";
interface IItem {
  link: string;
  alt: string;
  bgPath: string;
  arrPath: string;
  title: string;
}

const Nav = memo(() => {
  const [list, setList] = useState<IItem[]>([]);
  const getNavList = useCallback(async () => {
    const res = await fetch(`${preStaticUrl}/json/nav/nav.json`);
    const data = await res.json();
    setList(data);
  }, []);
  useEffect(() => {
    getNavList();
  }, []);
  return (
    <Swiper
      direction="horizontal"
      loop={true}
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={false}
      modules={[Autoplay]}
      className="game_swiper_nav"
    >
      {list.map((v, index) => (
        <SwiperSlide key={index}>
          <div className="nav_item">
            <img src={v.bgPath} className="nav_item_bg" />
            <a
              className={`nav_item_inner nav_item_inner${[index]}`}
              href={v.link}
              target="_blank"
              rel="noreferrer"
            >
              <p dangerouslySetInnerHTML={{ __html: v.title }} />
              <img src={v.arrPath} className="nav_item_arr" />
            </a>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}, isEqual);
export default Nav;
