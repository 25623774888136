import { isEqual } from "lodash";
import { memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import "./Rollup.scss";
import { preStaticUrl } from "../../../../constants/env_config";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const _preStaticUrl = preStaticUrl + "/img/zypher/rollup/";
type IRollupItem = {
  imgPath: string;
  title: string;
  content: string;
};
const rollUp: IRollupItem[] = [
  {
    imgPath: _preStaticUrl + "rollup_nav1.png",
    title: "Data Compatability",
    content:
      "Abstracted, dev-friendly relational databases to expedite game data-processing.",
  },
  {
    imgPath: _preStaticUrl + "rollup_nav2.png",
    title: "Instant Finality",
    content:
      "P2P layer optimization (e.g. special UDP protocol) & message transmission within small groups for 100 ms-level tick time.",
  },
  {
    imgPath: _preStaticUrl + "rollup_nav3.png",
    title: "Server Sharding",
    content:
      "Clustered nodes for world map / service distribution and retrieval algorithms (e.g. information synchronization).",
  },
];
const Rollup = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="animate">
      <Title
        title={t("All-in-One Sovereign Rollup")}
        subTitles={[
          t(
            "Zytron Kit provides a highly customizable Layer 3 sovereign roll up stack."
          ),
          t(
            "The most viable infrastructure for production-level massive multiplayer games."
          ),
        ]}
      />
      <div className="rollup_banner">
        <div className="rollup_banner_inner">
          <h3>3,000X</h3>
          <p>{t("Better UE for Gamers")}</p>
          <ul>
            {[
              {
                title: "30X",
                subTitle: "Cheaper",
              },
              {
                title: "30X",
                subTitle: "Faster",
              },
              {
                title: "10X",
                subTitle: "Game Genres",
              },
            ].map((v) => (
              <li key={v.subTitle}>
                <h4>{t(v.title)}</h4>
                <p>{t(v.subTitle)}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="rollup_banner_bg" />
      </div>
      <ul className="rollup_nav">
        {rollUp.map((v) => (
          <li key={v.title}>
            <img src={v.imgPath} alt={v.title} />
            <p className="rollup_nav_title">{t(v.title)}</p>
            <p className="rollup_nav_content">{t(v.content)}</p>
          </li>
        ))}
      </ul>
    </HomeSection>
  );
}, isEqual);

export default Rollup;
