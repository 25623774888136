import { isEqual } from "lodash";
import { memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import { preStaticUrl } from "../../../../constants/env_config";
import "./News.scss";
import SvgComponent from "../../../../components/SvgComponent/SvgComponent";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const _preStaticUrl = preStaticUrl + "/img/zypher/news/";

const newsList: INavItem[] = [
  {
    imgPath: _preStaticUrl + "news_nav1.png",
    title: "Year-end Review: From Zero to Autonomous World and Beyond",
    timestamp: "Feb 21, 2024",
    link: "https://zyphergames.substack.com/p/2023-review-from-zero-to-autonomous",
  },
  {
    imgPath: _preStaticUrl + "news_nav2.png",
    title: "Playtest Review: Anticipating #Web3Gaming's 'Candy Crush Moment'",
    timestamp: "Feb 21, 2024",
    link: "https://zyphergames.substack.com/p/2-weeks-2-million-txns-200k-gamers",
  },
  {
    imgPath: _preStaticUrl + "news_nav3.png",
    title: "BNB Chain Hackathon: Zypher Network Spotlight",
    timestamp: "Feb 21, 2024",
    link: "https://zyphergames.substack.com/p/hackathon-recap-autonomous-worlds",
  },
];
const News = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="news animate">
      <Title title={t("In the News")} />
      <div className="news_nav">
        <a
          href="https://zyphergames.substack.com"
          target="_blank"
          rel="noreferrer"
          className="all_media"
        >
          {t("All Media")}
          <SvgComponent src={preStaticUrl + "/img/icon/arrow-right.svg"} />
        </a>
        <ul>
          {newsList.map((v) => (
            <NavItem item={v} key={v.title} />
          ))}
        </ul>
      </div>
    </HomeSection>
  );
}, isEqual);

type INavItem = {
  imgPath: string;
  title: string;
  timestamp: string;
  link: string;
};
type INavItemProps = {
  item: INavItem;
};
const NavItem = memo(({ item }: INavItemProps) => {
  const { t } = useCustomTranslation();
  return (
    <li>
      <a href={item.link} target="_blank" rel="noreferrer">
        <img className="news_nav_img" src={item.imgPath} alt={item.title} />
        <p className="news_nav_title">{t(item.title)}</p>
        <p className="news_nav_time">{t(item.timestamp)}</p>
      </a>
    </li>
  );
}, isEqual);

export default News;
