import "./Bg.scss";
const Bg = () => {
  return (
    <div className="home_bg">
      <div className="home_bg2" />
      <div className="home_bg1" />
    </div>
  );
};
export default Bg;
