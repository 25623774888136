import { MutableRefObject } from "react";
import { atom } from "recoil";

export const activeLinkState = atom({
  key: "activeLinkState",
  default: "",
});

export const showHeaderBgState = atom({
  key: "showHeaderBgState",
  default: false,
});

export const showMenuState = atom({
  key: "showMenuState",
  default: false,
});
export interface ScrollRef extends MutableRefObject<HTMLElement | null> {}
export const buildScrollRefState = atom<ScrollRef | null>({
  key: "buildScrollRefState",
  default: null,
});
export const xxScrollRefState = atom<ScrollRef | null>({
  key: "xxScrollRefState",
  default: null,
});
export const solutionsScrollRefState = atom<ScrollRef | null>({
  key: "solutionsScrollRefState",
  default: null,
});
export const daoScrollRefState = atom<ScrollRef | null>({
  key: "daoScrollRefState",
  default: null,
});
