import { isEqual } from "lodash";
import { Children, memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import "./Solutions.scss";
import { preStaticUrl } from "../../../../constants/env_config";
import ALink from "../../../../components/ALink/ALink";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
type ISolutionsItem = {
  title: string;
  content: string;
  link?: string;
};
export const SolutionsList: ISolutionsItem[] = [
  {
    title: "Secret Engine",
    content:
      "A suite of zk-as-a-service SDKs, enabling information asymmetry essential for strategic gaming mechanisms, offering fully on-chain secrets and randomness with guaranteed fairness. Soon to be supported as AVSes.",
    link: "https://docs.zypher.network/zk/secret/overview/",
  },
  {
    title: "AW Engine",
    content:
      "A scalable, modular framework designed to support vertical hyper-scaling. Programmable through circuits or zkVM, with the z4 SDK specifically crafted for real-time multiplayer events, including PvP scenarios.",
    link: "https://docs.zypher.network/zk/aw/overview/",
  },
  {
    title: "Zytron Kit",
    content:
      "A Sovereign Rollup Stack engineered for the seamless deployment of dedicated gaming infrastructure, featuring 0 gas, 0.1s blocktime, zk pre-compiles and more. Optimized for highly interactive games such as MMOs and FOCGs.",
    link: "https://zytron.zypher.network/",
  },
  {
    title: "Redacted",
    content:
      "Deploy large-scale multiplayer games on [redacted] using your preferred programming languages (RUST, Solidity, WASM) and game engines (Bevy, Unity, Unreal). Pre-registration will be available soon.",
  },
];
const Solutions = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection id="solutions" classNames="animate">
      <Title
        title={t("Our Solutions")}
        subTitles={[
          "Zypher harnesses the power of zk technology and AI to revolutionize the architecture of on-chain games, targeting a global audience of 3 billion players.",
          // t("catering to a global audience of 3 billion players."),
        ]}
      />
      <div className="solutions_nav">
        {SolutionsList.map((v) => (
          <SolutionsNavItem item={v} key={v.title} />
        ))}
      </div>
    </HomeSection>
  );
}, isEqual);
const SolutionsNavItem = memo(({ item }: { item: ISolutionsItem }) => {
  const { t } = useCustomTranslation();
  return (
    <ALink
      className="solutions_nav_item"
      href={item.link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="solutions_nav_item_inner">
        <p className="solutions_title">{t(item.title)}</p>
        <p className="solutions_content">{t(item.content)}</p>
        <p className="solutions_more">
          {t("Learn More")}
          <img src={preStaticUrl + "/img/icon/arrow-right.svg"} />
        </p>
      </div>
      <div className="background-animation" />
    </ALink>
  );
}, isEqual);

export default Solutions;
