import { FC, memo } from "react";
import { preStaticUrl } from "../../../../../constants/env_config";
import { isEqual } from "lodash";
import "./commuinty.scss";
import SvgComponent from "../../../../../components/SvgComponent/SvgComponent";
interface IProps {
  className: string;
}
const CommunityLink: FC<IProps> = memo(({ className }: IProps) => {
  return (
    <div className={`${className} commuinty`}>
      <a
        href="https://twitter.com/Zypher_Network"
        target="_blank"
        rel="noreferrer"
      >
        <SvgComponent
          className="community_svg"
          src={preStaticUrl + "/img/layout/twitter.svg"}
        />
      </a>
      <a
        href="https://discord.com/invite/MKJZhS4p2T"
        target="_blank"
        rel="noreferrer"
      >
        <SvgComponent
          className="community_svg"
          src={preStaticUrl + "/img/layout/discord.svg"}
        />
      </a>
      <a
        href="https://zyphergames.substack.com"
        target="_blank"
        rel="noreferrer"
      >
        <SvgComponent
          className="community_svg"
          src={preStaticUrl + "/img/layout/medium.svg"}
        />
      </a>
      <a href="https://github.com/zypher-game" target="_blank" rel="noreferrer">
        <SvgComponent
          className="community_svg"
          src={preStaticUrl + "/img/layout/github.svg"}
        />
      </a>
      <a href="https://docs.zypher.network" target="_blank" rel="noreferrer">
        <SvgComponent
          className="community_svg"
          src={preStaticUrl + "/img/layout/gitbook.svg"}
        />
      </a>
    </div>
  );
}, isEqual);
export default CommunityLink;
