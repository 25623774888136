import { isEqual } from "lodash";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import "./Header.scss";
import Logo from "../widget/Logo/Logo";
import {
  activeLinkState,
  buildScrollRefState,
  daoScrollRefState,
  showHeaderBgState,
  showMenuState,
  solutionsScrollRefState,
} from "../../state/home";
import { useRecoilState, useRecoilValue } from "recoil";
import ExploreGamesBtn from "../ExploreGamesBtn/ExploreGamesBtn";
import NavComp from "../Nav/Nav";
import ALink from "../../../../components/ALink/ALink";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import IconMenu from "./iconMenu";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
export type IID =
  | "build"
  | "solutions"
  | "dao"
  | "Docs"
  | "TreasureArk"
  | "Layer3";
type INavItem = {
  title: string;
  link?: string;
  id: IID;
  index?: string;
};

const Nav: INavItem[] = [
  // {
  //   title: "Build",
  //   id: "build",
  //   index: "3",
  // },
  // {
  //   title: "Solutions",
  //   id: "solutions",
  //   index: "0",
  // },
  // {
  //   title: "DAO",
  //   id: "dao",
  //   index: "4",
  // },
  {
    title: "Developers",
    link: "https://docs.zypher.network/",
    id: "Docs",
  },
  {
    title: "Layer3",
    link: "https://zytron.zypher.network/layer3",
    id: "Layer3",
  },
  {
    title: "Treasure Ark",
    link: "https://ark.zypher.network",
    id: "TreasureArk",
  },
];

const Header = memo(() => {
  const active = useRecoilValue(activeLinkState);
  const showHeaderBg = useRecoilValue(showHeaderBgState);
  const isMobile = useIsMobile();
  const [showMenu, setShowMenu] = useRecoilState(showMenuState);
  const showMenuHandle = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu, setShowMenu]);

  return (
    <div id="header" className="header">
      <div
        className="header_inner"
        style={{
          backgroundColor: showHeaderBg ? "rgba(0,0,0, 0.9)" : "transparent",
        }}
      >
        <Logo />
        {!isMobile && (
          <div className="header_iinner">
            {Nav.map((v) => (
              <NavItem item={v} key={v.title} active={active} />
            ))}
          </div>
        )}
        <div className="header_fr">
          <ExploreGamesBtn showIcon={true} />
          {isMobile && (
            <div className="menu" onClick={showMenuHandle}>
              <IconMenu active={showMenu} />
            </div>
          )}
        </div>
      </div>
      <NavComp />
    </div>
  );
}, isEqual);

const NavItem = memo(({ item, active }: { item: INavItem; active: string }) => {
  const scrollRef = useRecoilValue(
    item.id === "build"
      ? buildScrollRefState
      : item.id === "dao"
      ? daoScrollRefState
      : item.id === "solutions"
      ? solutionsScrollRefState
      : buildScrollRefState
  );
  const { t } = useCustomTranslation();
  const [elementPosition, setElementPosition] = useState(0);

  useLayoutEffect(() => {
    const updateElementPosition = () => {
      const element = document.getElementById(item.id);
      if (element) {
        setElementPosition(element.getBoundingClientRect().top);
      }
    };
    updateElementPosition();
  }, [item.id]);

  const handleClick = () => {
    if (!item.link) {
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        // window.scrollTo(0, elementPosition);
      }
    }
  };

  return (
    <ALink
      href={item.link}
      className={`home_nav ${active === item.index ? "home_nav_on" : ""}`}
      onClick={handleClick}
    >
      {t(item.title)}
    </ALink>
  );
}, isEqual);

export default Header;
