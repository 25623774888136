import { isEqual } from "lodash";
import { Suspense, memo } from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import HomePage from "../view/homePage/homePage";
import { useInit } from "../hooks/useInit";
import { RecoilRoot } from "recoil";
import { IsMobileProvider } from "../provider/IsMobileProvider/IsMobileProvider";
import "../utils/i18n";

const RouterIndex = memo(() => {
  useInit();
  return (
    <Suspense fallback={<></>}>
      <RecoilRoot>
        <IsMobileProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
            </Routes>
          </BrowserRouter>
        </IsMobileProvider>
      </RecoilRoot>
    </Suspense>
  );
}, isEqual);
export default RouterIndex;
