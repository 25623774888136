import "swiper/css";
import "./navigation.scss";
import "./pagination.scss";
import "./homePage.scss";
import Banner from "./components/Banner/Banner";
import Bg from "./components/widget/Bg/Bg";
import Header from "./components/Header/Header";
import { useScroll } from "./hook/useScroll";
import Solutions from "./components/Solutions/Solutions";
import Rollup from "./components/Rollup/Rollup";
import Resources from "./components/Resources/Resources";
import Evolve from "./components/Evolve/Evolve";
import Dao from "./components/Dao/Dao";
import Manifesto from "./components/Manifesto/Manifesto";
import Games from "./components/Games/Games";
import Partners from "./components/Partners/Partners";
import News from "./components/News/News";
import Social from "./components/Social/Social";
import Footer from "./components/Footer/Footer";
import "@reach/dialog/styles.css";
import MenuDialog from "./components/dialog/MenuDialog";
import Nav from "./components/Nav/Nav";
const DashboardPage = () => {
  useScroll();
  return (
    <>
      <div className="home_p">
        <Header />
        <Banner />
        <Solutions />
        <Rollup />
        <Resources />
        <Evolve />
        <Dao />
        <Manifesto />
        <Games />
        <Partners />
        <News />
        <Social />
        <Footer showLogo={true} />
      </div>
      <Bg />
      <MenuDialog />
    </>
  );
};
export default DashboardPage;
