import React from "react";
import ReactDOM from "react-dom/client";
import RouterIndex from "./router/router";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterIndex />);

reportWebVitals();
