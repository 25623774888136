import { isEqual } from "lodash";
import { memo } from "react";

const ALink = memo(({ href, children, ...props }: any) => {
  if (href) {
    return (
      <a {...props} href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  return <div {...props}>{children}</div>;
}, isEqual);
export default ALink;
