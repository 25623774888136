import { isEqual } from "lodash";
import { memo } from "react";
import { preStaticUrl } from "../../../../constants/env_config";
import "./Manifesto.scss";
import Button from "../../../../components/Button/Button";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const Manifesto = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <div className="manifesto">
      <img
        src={preStaticUrl + "/img/zypher/manifesto_bg.jpg"}
        className="manifesto_bg"
      />
      <div className="manifesto_inner">
        <h2>THE DAO MANIFESTO</h2>
        <p className="manifesto_p1">
          {t("Smart contracts are social contracts.")}
        </p>
        <p className="manifesto_p2">
          {t(
            "We recognize the gaming realm as a fertile microcosm, a testing ground for technologies and systems with the potential to redefine society."
          )}
        </p>
        <Button
          className="manifesto_btn"
          classNameInner="manifesto_btn_inner"
          label={t("More Insights")}
          onClick={() => {
            window.open(
              "https://zyphergames.substack.com/p/introducing-the-zypher-games-incentives",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  );
}, isEqual);
export default Manifesto;
