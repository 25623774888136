import { memo } from "react";
import "./Dao.scss";
import { isEqual } from "lodash";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import { preStaticUrl } from "../../../../constants/env_config";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
import Button from "../../../../components/Button/Button";
import { useClick } from "../../../../hooks/useClick";
const _preStaticUrl = preStaticUrl + "/img/zypher/dao/";
const Dao = memo(() => {
  const { handleEconomics } = useClick();
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="animate" id="dao">
      <Title
        title={t("Zypher DAO")}
        subTitles={[
          t(
            "One or more people create a set of rules in the form of decentralized codes"
          ),
          t(
            "based upon which collective efforts are made to build a community of things."
          ),
        ]}
      />
      <Button
        onClick={handleEconomics}
        className="dao_btn element-from-bottom"
        label="Econ paper"
        classNameInner={"dao_btn_inner"}
      />
      <ul className="dao_nav">
        {[
          {
            imgPath: "dao_nav1.png",
            title: "Governance",
            content:
              "The DAO plays an active role in governance by participating in the decision-making process beyond automated policy making. Members can vote on a wide range of proposals, including but not limited to Treasury Management, Strategic Planning, Fiscal Policies, and more.",
          },
          {
            imgPath: "dao_nav2.png",
            title: "ZK-as-a-Service Fees",
            content:
              "The DAO maintains and registers all zk-as-a-service solutions as Actively Validated Services for enhanced, more economic security and increased trust guarantees. The DAO collectively decides the custom rewards/slashing logic of those AVSes.",
          },
          {
            imgPath: "dao_nav3.png",
            title: "Layer3 Governance",
            content:
              "The DAO will be maintaining a first pioneer Zytron Network featuring zero gas, 0.1s block time and more game-specific modules. Members can gain zero gas privileges, represented as Soulbound Tokens (SBTs).",
          },
        ].map((v) => (
          <NavItem item={v} key={v.title} />
        ))}
      </ul>
    </HomeSection>
  );
}, isEqual);
type INavItem = {
  imgPath: string;
  title: string;
  content: string;
};
type INavItemProps = {
  item: INavItem;
};
const NavItem = memo(({ item }: INavItemProps) => {
  const { t } = useCustomTranslation();
  return (
    <li>
      <div className="dao_nav_inner">
        <NavImg src={item.imgPath} alt={item.title} />
        <NavTitle label={t(item.title)} />
        <NavContent label={t(item.content)} />
      </div>
    </li>
  );
}, isEqual);
const NavImg = memo(({ src, alt }: { src: string; alt: string }) => {
  return <img className="dao_nav_img" src={_preStaticUrl + src} alt={alt} />;
}, isEqual);
const NavTitle = memo(({ label }: { label: string }) => {
  return <p className="dao_nav_title">{label}</p>;
}, isEqual);
const NavContent = memo(({ label }: { label: string }) => {
  return <p className="dao_nav_content">{label}</p>;
}, isEqual);
export default Dao;
