import { isEqual } from "lodash";
import { memo } from "react";
import "./Title.scss";
const Title = memo(
  ({
    title,
    subTitles,
    otherComp,
  }: {
    title: string;
    subTitles?: string[];
    otherComp?: any;
  }) => {
    return (
      <div className="home_title">
        <h2>{title}</h2>
        {subTitles
          ? subTitles.map((subTitle) => <p key={subTitle}>{subTitle}</p>)
          : null}
        {otherComp}
      </div>
    );
  },
  isEqual
);
export default Title;
