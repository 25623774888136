class Storage {
  private storage: any;

  constructor() {
    this.storage = typeof window !== "undefined" ? window.localStorage : null;
  }

  public set(key: string, value: any): void {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  public get(key: string): any {
    if (this.storage) {
      const item = this.storage.getItem(key);
      return item ? JSON.parse(item) : null;
    }
    return null;
  }

  public removeItem(key: string): void {
    if (this.storage) {
      this.storage.removeItem(key);
    }
  }

  public clear(): void {
    if (this.storage) {
      this.storage.clear();
    }
  }
}

export default new Storage() as Storage;
