import { isEqual } from "lodash";
import { memo } from "react";
import HomeSection from "../widget/HomeSection/HomeSection";
import Title from "../widget/Title/Title";
import { preStaticUrl } from "../../../../constants/env_config";
const _preStaticUrl = preStaticUrl + "/img/zypher/evolve/";
import "./Evolve.scss";
import { useCustomTranslation } from "../../../../hooks/useCustomTranslation";
const Evolve = memo(() => {
  const { t } = useCustomTranslation();
  return (
    <HomeSection classNames="evolve animate">
      <Title
        title={t("Evolve, Autonomously")}
        subTitles={[
          t(
            "In AW (Autonomous Worlds) games, the blockchain is used as an alternative to centralized game servers, with all players indexing from and writing to a shared state on-chain."
          ),
        ]}
      />
      <div className="evolve_banner">
        <div className="evolve_banner_inner">
          <div className="evolve_banner_fl">
            <img src={_preStaticUrl + "evolve_icon.svg"} alt="evolve" />
            <p className="evolve_banner_title">
              {t("Redefined Digital Ownership")}
            </p>
            <p className="evolve_banner_content">
              {t(
                "Verifiable attestations and asset programmability grant gamers true, composable digital ownership of achievements & assets & the game itself, leveraging blockchain's inherent transparency and interoperability."
              )}
            </p>
          </div>
          <div className="evolve_banner_fr">
            <img
              className="evolve_banner_fr_bg"
              src={_preStaticUrl + "evolve_banner_fr_bg.png"}
              alt="Sovereign Rollup"
            />
            <div className="evolve_banner_fr_card">
              <img
                className="evolve_banner_fr_card_img"
                src={_preStaticUrl + "evolve_nft.png"}
                alt="evolve nft"
              />
              <div className="evolve_banner_bottom">
                <img
                  src={_preStaticUrl + "evolve_ava.png"}
                  alt="avatar"
                  className="evolve_banner_bottom_avatar"
                />
                <p>{t("Owner: Alice")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="evolve_banner_bg" />
      </div>
      <ul className="evolve_nav">
        {[
          {
            imgPath: "evolve_nav1.png",
            title: "Smart-contract Encoded",
            content:
              "Game logic is entirely encoded in smart contracts for on-chain execution, ensuring tamper-proof, truestless game loops.",
          },
          {
            imgPath: "evolve_nav2.png",
            title: "Endure & Evolve Infinitely",
            content:
              "Allows the community to create content beyond predefined rules, independent of developer-provided clients.",
          },
          {
            imgPath: "evolve_nav3.png",
            title: "Economic HyperStructure",
            content:
              "Tokenization and governance primitives are integral, aligning monetization flow for developers and players.",
          },
        ].map((v) => (
          <NavItem item={v} key={v.title} />
        ))}
      </ul>
    </HomeSection>
  );
}, isEqual);
type INavItem = {
  imgPath: string;
  title: string;
  content: string;
};
type INavItemProps = {
  item: INavItem;
};
const NavItem = memo(({ item }: INavItemProps) => {
  const { t } = useCustomTranslation();
  return (
    <li>
      <NavImg src={item.imgPath} alt={item.title} />
      <NavTitle label={t(item.title)} />
      <NavContent label={t(item.content)} />
    </li>
  );
}, isEqual);
const NavImg = memo(({ src, alt }: { src: string; alt: string }) => {
  return <img className="evolve_nav_img" src={_preStaticUrl + src} alt={alt} />;
}, isEqual);
const NavTitle = memo(({ label }: { label: string }) => {
  return <p className="evolve_nav_title">{label}</p>;
}, isEqual);
const NavContent = memo(({ label }: { label: string }) => {
  return <p className="evolve_nav_content">{label}</p>;
}, isEqual);
export default Evolve;
