import { useRecoilState } from "recoil";
import { isMobileState } from "../../state/global";
import { createContext, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";

export const IsMobileContext = createContext<boolean | undefined>(undefined);
export const IsMobileProvider = ({ children }: any) => {
  const [isMobile, setIsMobile] = useRecoilState(isMobileState);
  const size = useWindowSize();

  useEffect(() => {
    const nowIsMobile = size.width < 768;
    setIsMobile(nowIsMobile);
  }, [size.width, isMobile, setIsMobile]);

  return (
    <IsMobileContext.Provider value={isMobile}>
      {children}
    </IsMobileContext.Provider>
  );
};
