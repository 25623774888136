import { isEqual } from "lodash";
import { memo } from "react";
import { preStaticUrl } from "../../../../../constants/env_config";

const Logo = memo(() => {
  return (
    <a href="/" className="header_logo">
      <img src={`${preStaticUrl}/img/zypher/logo.svg`} alt="Zypher game" />
    </a>
  );
}, isEqual);
export default Logo;
